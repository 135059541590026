export const tr_messages = {
  yes: "Evet",
  no: "Hayır",
  n: "№",
  create: "Oluştur",
  age: "Yaş",
  limit: "Katılımcı sayısı",
  new_m: "Yeni {m}",
  edit_m: "{m} öğesini düzenle",
  edit: "Düzenle",
  main: "Ana sayfa",
  search: "Bul",
  gender: "Cinsiyet",
  position_2: "Pozisyon (2.)",
  department: "Bölümler",
  department_: "Bölüm",
  department_y: "Bölümler",
  settings: "Ayarlar",
  datas: "Tarih",
  full_name: "Ad ve Soyadı",
  created_at: "Oluşturulma tarihi",
  updated_at: "Değiştirilme tarihi",
  name: "Adı",
  nameGiven: "Ad",
  nameFull: "İsim",
  requirements: "Gereksinimler",
  requirements_y: "Talepler",
  reason: "Sebep",
  reasontype: "Sebep türü",
  staffAssentReason: "Geri Bildirimler",
  reason_y: "Sebepler",
  priority: "Öncelik",
  priority_y: " Öncelikler",
  position: "Pozisyon",
  position_p: "Pozisyonlar",
  graphicType: "Grafik türü",
  graphicType_p: "Grafik türleri",
  company: "Şirket",
  company_y: "Şirketler",
  language: "Dil bilgisi",
  language2: "Dil bilgisi",
  language_y: "Dil bilgisi",
  currency: "Para birimi",
  currency_y: "Para birimi",
  salary_currency: "Para birimi",
  salary_currency_y: "Para birimi",
  branch: "Şubeler",
  branch_a: "Şube",
  branch_parental: "Ana şube",
  skill: "Beceriler",
  skill_y: "Beceri",
  total_working_day: "Çalışma haftası",
  graphic: "Çalışma saatleri",
  language_graphic_type: "Lütfen bir başlık girin",
  employmentType: "İstihdam türü",
  province: "Şehir",
  region: "Şehir",
  region_: "Bölge",
  vacancy: "Pozisyon",
  last_name: "Soyadı",
  middle_name: "Baba adı",
  date_of_birth: "Doğum tarihi",
  phone_number: "Telefon",
  email: "e-posta",
  status: "Durum",
  role: "Roller",
  security_location: "Kontrol noktası",
  securityLocations_y: "Kontrol noktası",
  deviceType: "Cihaz Türleri",
  deviceType_y: "Cihaz Türleri",
  department_title: "Bölümler ve alt bölümler",
  parent_department_id: "Ebeveyn bölümü",
  min_wage_y: "Min ücret",
  max_wage_y: "Max ücret",
  phone_number_y: "Telefon numarası",
  summary: "Özet",
  UploadFile: "Dosyayı yükle",
  UploadFileText: " 500 kb'den küçük jpg / png dosyaları ",
  serial_number: "Seri numarası",
  ip_address: "IP adresi",
  device: "Cihazlar",
  device_y: "Cihaz",
  income: "Gelir düzeyi",
  work_type: "Çalışan Durumu",
  staff_work_type: "İş türü",
  district_id: "Mahalle ",
  district: "Mahalle",
  nation: "Ulus",
  nation_y: "Ulus",
  code: "Kod",
  okurs: "Ters kurs",
  profile: "Profil",
  dateAdoption: "Kabul tarihi",
  staffTest: "Deneme ",
  operating: "Geçerli dönem",
  dismissed: "Kovuldu",
  save: "Kaydet",
  close: "Kapat",
  candidates: "Adaylar",
  find: "ara",
  delete: "Sil",
  daily: "Günlük",
  from: "Kimden",
  before: "Önce",
  dinner: "Öğle Yemeği",
  users: "Kullanıcılar",
  user: "Kullanıcı",
  password: "Şifre",
  confirm_password: "Şifreyi tekrarla",
  finance: "Maliyet",
  client: "Müşteri",
  access_rights: "Erişim hakları",
  active: "Aktiv",
  inactive: "Aktif değil",
  working_hours_report: "Çalışma saati raporu",
  age: "Yaş",
  nv_monitoring: "Günlük göstergeler ödemesi",
  new_incoming_payment: "Yeni gelen ödeme",
  incoming: "Gelen",
  outgoing: "Giden",
  employees: "Çalışanlar",
  payment_type: "Ödeme türü",
  amount: "Miktar",
  check: "Hesap",
  check_a: "Hesaplar",
  salary_type: "Maaş türü",
  salary: "Maaş",
  bank: "Banka",
  checking_account: "Çek hesabı",
  expiration_date: "Bitiş tarihi",
  comment: "Yorum",
  overtime: "Fazla Mesai",
  total: "Toplam:",
  being_late: "Gecikmeler",
  other_penalties: "Diğer cezalar",
  penalties: "Cezalar",
  bonuses: "Bonuslar",
  on_hands: "Eller serbest",
  passport_number: "Pasaport serisi",
  passport_issued_by: "Veren makam",
  passport_valid_until: "Pasaportun geçerlilik süresi",
  inn: "KDV",
  telegram: "Telegram",
  job: "İş",
  hiring_date: "İşe alma tarihi",
  manager: "Yönetici",
  allow_th_employee_to_access_the_system:
    "Çalışanın sisteme erişmesine izin ver.",
  allow_salary: "Maaş.",
  add_candidates: "Aday ekle",
  description: "Açıklama",
  photo: "Fotoğraf",
  min: "min",
  max: "max",
  recruiting: "İşe alma",
  desktop: "Masaüstü",
  indicators: "Gösterge",
  journal: "Dergi",
  quit: "Çık",
  branch_name: "Şube adı",
  mfo: "MFI",
  number: "Numara",
  account: "Hesap",
  card_number: "Kart numarası",
  penalty_name: "Cezanın adı",
  bonus_name: "Bonusun adı",
  payment_date: "Ödeme tarihi",
  adaptation: "Adaptasyon",
  agreement_employees: "Sözleşmeler(ÇALIŞANLAR)",
  login: "Giriş yap",
  permission: "İzinler",
  synchronization: "Senkronizasyon",
  tests: "Deneme süresinde",
  wage: "Ücretler",
  dafault: "Varsayılan",
  real_amount: "Gerçek miktar",
  bonus_amount: "Bonus tutarı",
  payment_for: "İş ödemeler",
  family_location: "Medeni durum",
  url: "Bağlantı",
  age: "Yaş",
  wage: "Maaş",
  complete_form: "Formu doldurun",
  KPP: "Giriş Kontrol Noktası ",
  guest: "Misafir",
  card: "Araba",
  copyUrl: "URL'yi kopyala",
  vacancyNotAvailable: "Boş pozisyon mevcut değil",
  fire_staff: "İşten çıkarma",
  user_monitoring: "Kullanıcıları izleme",
  car_driver: "Birincil şoför",
  new_operation: "Yeni İşletim Sistemi",
  update_operation: "İşletim Sistemini düzenle",
  transport: "Ulaşım",
  arrival_date_time: "Giriş",
  arrival_date: "Varış tarihi",
  arrival_time: "Varış saati",
  difference: "Fark",
  managed_task: "Görevi yönet",
  employee_entrance: "Çalışan Girişi",
  employee_exit: "Çalışan çıkışı",
  entrance: "Giriş",
  exit: "Çıkış",
  type: "Tip",
  district: "Mahalle",
  min_wage: "Asgari ücret",
  max_wage: "Maksimum maaş",
  min_age: "Minimum geri dönüş",
  max_age: "Maksimum geri dönüş",
  description: "Açıklama",
  symbol: "Sembol",
  staff: "Çalışanlar",
  active: "Etkin",
  inactive: "Etkin değil",
  role_is_not_set: "Rol atanmamış ",
  new: "Yeni",
  amount: "miktarlar",
  amount_a: "Miktar",
  comment: "Yorum",
  attendance_type: "Katılım türü",
  port: "Liman",
  car: "Araba",
  guest_coming_reason: "Kime geldiniz?",
  visit_date: "Varış tarihi",
  visit_date_time: "Varış tarihi ve saati",
  visit_time: "Giriş",
  left_date: "Giriş",
  left_date_time: "Giriş ve saati",
  left_time: "Çıkış zamanı",
  car_number: "Araç numarası",
  car_color: "Makinenin rengi",
  car_type: "Makine tipi",
  car_model: "Makine modeli",
  additional_information: "Ek bilgi",
  add_guest: "Misafir ekle",
  add_company: "Şirket ekle",
  address: "Adres",
  add_car: "Makine ekle",
  add_phone_number: "Telefon numarası ekle",
  visitor: "Ziyaretçi",
  update_visitor: "Ziyaretçiyi düzenle",
  total_visit: "Toplam ziyaret",
  car_information: "Araç bilgisi",
  supported_formats: "Dosya biçimi: doc, docx, pdf, jpg, jpeg, gıf, svg, png",
  size_limit_5: "Dosya boyutu: 5 mb",
  candidates: "Adaylar",
  attendance: "Katılım",
  time: "Zaman",
  attendance_type: "Katılım türü",
  img: "fotoğraf",
  verfired_type: "Onay türü",
  today: "Bugün",
  refresh: "Yenile",
  exit_target: "Çıkış hedefi",
  expected_emp: "Giriş planı",
  chel: "İnsan",
  factual_emp: "Giriş gerçeği",
  transport_type: "Araba tipi",
  client_transport: "Karşı taraf arabasi",
  company_transport: "Şirketin arabası",
  product: "Ürün",
  product_name: "Ürün adı",
  quantity: "Miktar",
  measurement_type: "Boyut türü",
  copy: "Kopyala",
  add_new: "Yeni ekle",
  shipping_letter: "Nakliye mektubu",
  user_activity: "Kullanıcı etkinliği",
  id: "ID",
  method_type: "Yöntem türü",
  browser_type: "Tarayıcı türü",
  social: "Kaynak",
  where_know: "Nereden öğrendiniz",
  line: "Çizgi",
  accept: "Kabul et",
  education: "Eğitim",
  header: "Konu",
  title: "Açıklama",
  period: "Avansın alındığı tarih",
  left_time: "Ayrılma zamanı",
  left_time: "Время ухода",
  Tuesday: "Salı",
  Wednesday: "Çarşamba",
  Thursday: "Perşembe",
  Friday: "Cuma",
  Saturday: "Cumartesi",
  Sunday: "Pazar",
  Monday: "Pazartesi",
  "settings profile": "Profil ayarları",
  settingCompany: "Şirketler hakkında bilgi",
  logout: "çıkmak",
  category: "Kategori",
  categories: "Kategoriler",
  telegram_managers: "Telgram mesajı",
  telegram_manager: "Telgram mesajı",
  vacation: "Tatil muhasebesi",
  children: "Çocuksu",
  newcomestaffs: "Işe yeni kabul edilenler",
  completestaffprofile: "Profile tamamlama durumu %",
  staffTests: "Deneme süresinde",
  operatings: "Çalışanlar",
  dismisseds: "Kovulanlar",
  average_age: "Yaş ortalaması",
  arrivals_and_departures: "Gelen ve gidenlerin oranı",
  accepted_CV: "Kabul edilen özgeçmişler",
  total_qty: "Toplam miktar",
  present: "Bulunuyorlar",
  missing: "Çekilecekler",
  past_birthdays: "Geçen doğum günleri",
  today_birthdays: "Bugünkü doğum günleri",
  expected_birthdays: "Gelecek doğum günleri",
  count_gender: "Erkek ve kadinlarin sayisi",
  not_data: "Henüz veri yok",
  reasons_for_layoffs: "Kovulma nedeni",
  official_and_unofficial: "Resmiy ve resmiy olmayan çalışan sayısı",
  faceidregistration: "Kayıt Face ID",
  registered: "Kayıtlı",
  not_registered: "Kayıtlı olmayan",
  compleinProfile: "Tamamlanan profillerin şubeye gore payi, %",
  worksPlace: "İş deneyimi",
  salary_by_branches: "Şubelere göre ortalama maaş",
  districtByStaff: "Bölgeye göre çalışan sayısı",
  will_arrive: "Gelecekler",
  not_come: "Gelmeyecekler",
  answer_expected: "Cevap bekleniyor",
  assign_role: "Rol atama",
  excel: "Excel",
  weekly: "Hafta",
  monthly: "Ay",
  yearly: "Yıl",
  coming: "Geliş",
  pending: "Beklemede",
  additional_rest: "ekstra dinlenme",
  o_salary: "İş ödemeler",
  bonuses: "Bonuslar",
  avance: "Avans",
  tms: "TMC'YE Giriş / Çıkış",
  employee_kpp: "Çalışanların Gelişi / Bakımı",
  operation: "İşletim sistemi",
  company_machines: "Şirket Arabaları",
  leave_requests: "Izin başvurusu",
  take_assents: "Izin başvurusu",
  report_card: "Karne",
  structure: "Yapı",
  additional_work: "Ek iş",
  additional_works: "Ek iş",
  complaint: "Şikayet",
  calendar: "Takvim",
  fio: "Soyadı ",
  general_working_day: "Genel iş günü",
  general_working_hour: "Toplam çalışma saatleri",
  records_of_the_month:
    "Ay sayısına göre iş katılımları ve iş görmeme işaretleri",
  unrevealed: "Görünmeyenler",
  waiting: "Bekleyen",
  received: "Kabul edildi",
  denied: "Reddedildi",
  all: "Tüm",
  those_present: "Mevcut olanlar",
  released: "Çıkanlar",
  missing: "Eksik olanlar",
  with_reason: "Nedeni ile",
  without_reason: "Sebepsiz",
  online: "Çevrimiçi",
  confirm: "Doğrulamak",
  approved: "Onaylandı",
  rejected: "Reddedildi",
  date_time: "Tarih ve saat",
  performance_type: "Performans türü",
  qty: "miktar",
  details: "detayları",
  permis: "izin",
  parental_permission: "Ebeveyn izni",
  translated_resolution: "Tercüme edilmiş izin",
  children_permission: "Çocuk izni",
  children_translated_permission: "Çocuklar Tercüme izni",
  telegram_password: "Telgram şifresi",
  last_active: "Son etkinlik",
  subordinates: "Astlar",
  document: "Evrak",
  file: "Dosyalar",
  additional_information: "Ek bilgi",
  work_history: "İş geçmişi",
  cancel: "İptal",
  personal: "Kişisel",
  limit_work_time: "Çalışma normu (saat)",
  actual_working_time: "Gerçek çalışma süresi (saat)",
  salary_rotation: "Maaş ve rotasyonlar",
  rotation: "Rotasyonlar",
  quality_control: "Kalite kontrolü",
  process: "Süreç",
  name_process: "İsim süreci",
  norms: "Norm",
  fact: "Gerçek",
  assigned_salary: "Belirlenen maaş",
  last_month: "Geçen ay",
  current_month: "Geçerli ay",
  allowed: "Caiz",
  rejected: "Reddedildi",
  request_time: "istek zamanı",
  licenses_and_certificates: "Lisanslar ve sertifikalar",
  gave_out: "Veriyordu",
  issued: "Verilmiş",
  release_date: "Yayın tarihi",
  health_information: "Sağlık Bilgileri",
  growth: "Büyüme",
  weight: "Ağırlık",
  blood_type: "Kan grubu",
  locations_health_information: "Yerler Sağlık Bilgileri",
  foreign_languages: "Yabancı diller",
  degree: "Derece",
  lang: "Dil",
  mobil_number: "Cep telefonu numarası",
  work_phone: "İş telefonu",
  facebook_url: "Facebook URL'si:",
  instagram_url: "İnstagram URL'si:",
  twitter_url: "Twitter URL'si:",
  linkedIn_url: "LinkedIn URL'si:",
  telegram_username: "Telegram'daki kullanıcı adı",
  tiktok_username: "Tiktok'ta kullanıcı adı",
  educational_institution: "Okul",
  faculty: "Fakülte",
  beginning_date: "Başlangıç tarihi",
  expiration_date: "Bitiş tarihi",
  skill: "Beceri",
  not_coming_day: "olmayan gün",
  hours_or_more: "{m} saat veya daha fazla",
  less_than_hours: "{m} saatten az",
  day_absence: "İzin günü",
  not_come_work_day: "Ishga kelmagan kun",
  complaints: "Şikayetler",
  subject_complaint: "Şikayet konusu",
  izn: "İzin",
  date_of_departure: "Gelim tarihi",
  arrival_date: "Gitim tarihi",
  place_work: "İş yerleri",
  place_work_name: "İş yerinin adı",
  data_from_to: "Gelen ve giden tarih",
  display_and_wallpaper: "Masaüstü ve ekran koruyucu",
  dynamic_wallpaper: "Dinamik duvar kağıdı",
  notes: "Notlar",
  from_data: "ilk tarih ",
  to_data: "son tarih",
  add_document: "Belge ekle",
  labor_contract: "İş sözleşmesi",
  material_liability: "Maddi sorumluluk",
  confidentiality_agreement: "Gizlilik Sözleşmesi",
  employment_contract: "İş sözleşmesinin feshi",
  finance_training: "Finansman Eğitimi",
  plastic_card: "Kredi kart",
  card_number: "Kart numarası",
  date_of_expiry: "Geçerlik süresi",
  card_organ: "Banka adı",
  amount_per_hour: "Saat başına miktar",
  unofficial_amount: "Resmiy olmayan maaş tutarı",
  official_amount: "Resmi maaş tutarı",
  type_salary: "Maaş Türü",
  amount_money: "Para miktarı",
  in_hour: "Saat başı.",
  access_control: "Erişim kontrolü",
  id_employee: "Çalışan ID",
  contact: "Temas",
  add_skill: "Beceri ekle",
  Level: "Düzey",
  children: "Çocuk",
  sort_panel: "Gösterge Paneli",

  schedule: "Yerleşmesi",
  new_version: "Yeni versiyon",
  old_version: "Eski versiyon",
  country_passport: "Vatandaşlık",
  sort_panel: "Gösterge paneli",
  Send_Manager: "Send Manager",
  departure_time: "Kalkış saati",
  access_time: "Erişim süresi",
  model: "Modellik",
  izn_password: "Bot şifresi",
  causes_operating_system: "İşletim sisteminin nedenleri",
  active_machines: "Şirketin aktif makineleri",
  visitors_to_company_employees: "Şirket çalışanlarına ziyaretçiler",
  number_of_visitors_periods: "Dönemlere göre ziyaretçi sayısı",
  late_to_works: "Ishga kech qolganlar",
  coming_date_time: "Kelish kuni va vaqti",
  coming_time: "Kelish vaqti",
  in_count: "Kirishlar soni",
  out_count: "Chiqishlar soni",
  total_toilet_minuts: "Umumiy vaqt",
  director: "Директор",
  country: "Страна",
  logo: "Логотип",
  web_site: "Веб-сайт",
  application: "Ariza",
  explanatoryLetter: "Tushuntirish xati",
  explanatory_letters: "Tushuntirish xati",
  active_works: "Faol xodim | Obod Xudud",
  active_work: "Faol xodim | Obod Xudud",
  application_letter: "Заявительные письма",
  applications: "Заявительные письма",
  active_person: "Faol xodim",
  prosperous_place: "Obod Xudud",
  parent_company_id: "Bosh kompaniya",
  manual: "Руководство создано",
  leader: "Raxbar",
  numeric_currency_code: "Цифровой код валюты",
  letter_currency_code: "Буквенный код валюты",
  payed: "To'langan",
  advance_salary: "Avans",
  advance_salaries: "Avans",
  pentaly_salary: "Штрафы",
  hour: "h",
  minut: "m",
  dateuvalen: "Bo'shatilgan sana",
  department_full:
    "Bu bo'limda ishchi o'rni to'lgan yoki nechta ishchi olish kerkaligi ko'rsatilmagan!",
  pinfl: "PINFL",
  organizations: "Организация",
  dosnt_have_salary: "Ish haqqi kiritilmaganlar",
  have_salary: "Ish haqqi kiritilganlar",
  value: "Qiymat",
  percent: "Foiz",
  value_type: "Qiymat turi",
  bonus_penalty_reasons: "Причины санкции | бонусы",
  work_times: "Ishlash vaqtlari",
  online_works: "Online Ishlar",
  vacation_managements: "Управление отпуском",
  vacation_management: "Управление отпуском",
  accept_vacation_managment: "Месяц отпуска",
  not_attached: "Не прикреплен",
  pnfl: "ПИНФЛ",
  vacation_graphics: "График отпуска",
  vacation_graphic: "График отпуска",
  official_date: "Дата перехода на официальный",
  back: "Назад",
  pinfl: "ПИНФЛ",
  send_channel: "Отправить канал",
  vacation_planes: "План отпуска",
  anecdotes: "Анекдоты",
  anecdote: "Анекдот",
  content: "Содержание",
  request: "Запросы",
  fixed: "Фиксированная оплата",
  hourly: "Почасовая оплата",
  income_tax: "Подоходный налог",
  start_date_time: "Дата и время начала",
  end_date_time: "Дата и время окончания",
  settingApp: "Настройки GOHR",
  holiday_work_contribution: "Оплата в праздничные дни",
  question_category_list: "Категория вопроса",
  question_direction_list: "Направление вопроса",
  question_type_list: "Тип вопроса",
  validator: "Валидатор",
  telegram_quiz: "Телеграм викторина/тест",
  started_date: "Дата начала",
  point: "Балл",
  questions: "Вопросы",
  question: "Вопрос",
  quiz_plan: "План викторины",
  daly_question_count: "Ежедневное количество вопросов",
  from_date: "Дата от",
  to_date: "Дата до",
  send_question_time: "Отправить время",
  what_for: "Для чего",
  media_type: "Тип носителя",
  closed: "Завершен",
  started: "Не завершен",
  sanction_type: "Тип санкции",
  sanction_type_items: "Элемент типа санкции",
  fix: "Исправить",
  create_sanction_late: "Время создания (минута)",
  is_free: "Свободное посещение (сдельная)",
  by_connecting_to_the_device: "При подключении к устройству",
  in_vacation: "В отпуске",
  lay_off_date: "Дата увольнения",
  application_dismissals: "Заявления об увольнении",
  send_acceptor: "Отправить в Telegram",
  inventories: "Инвентарь",
  inventory: "Инвентарь",
  inventory_categories: "Категории инвентаря",
  inventory_category: "Категория инвентаря",
  all_amount: "Общая сумма",
  online_or_ofline: "Дистанционная и/или Дополнительная",
  all_work_days: "Общие рабочие дни",
  normal_salary: "Оплата по норме",
  transport_salary: "Транспортные расходы и поощрения",
  hourly_salary: "Повременная оплата",
  all_paid_salary: "Общая требуемая оплата",
  "Общие сохраненные": "Общие сохраненные",
  candidate_new: "Недавно отправлено",
  candidate_called_interview: "Позвали на собеседование",
  candidate_approved_employment: "Прошел собеседование",
  monthly_limit_work_day: "Нормативный день в месяц",
  monthly_limit_work_hour: "Нормативное время в месяц",
  plane_graphic: "План графика",
  logout_question: "Вы уверены, что выйдете из системы?",
  operation_canceled: "Операция отменена!",
  device_count_type: "Тип счетчика устройств",
  date_uvalen_day_count: "Срок увольнения",
  annual_medical_leave: "1-й ежегодный больничный",
  oficial_salary: "Оф / С",
  un_oficial_salary: "Н / С",
  all_salary: "Общая / С",
  work_day_count: "Рабочий день",
  average_work_time: "Рабочий час",
  mounth_limit: "Стандартное <br/> рабочее времяиш",
  all_work_time: "Общий<br/> рабочий час",
  online_and_additional_works:
    "<div >Дистанционная или<br/> Дополнительная</div>",
  all_worked_day: "Общий рабочий день",
  per_hour: "Повременная оплата",
  normal_pay_salary: "Норма буйича<br/> иш хаки",
  all_pay_salary: "Общая <br/> требуемая оплата",
  all_must_paying_salary: "Общая требуемая <br/> оплата",
  this_month_vacation: "Отпуск в этом месяце",
  next_month_vacation: "Отпуск на следующем месяце",
  applicationType: "Типы заявления",
  seer: "Информированный сотрудник",
  whose_side: "Xarajat kim tomonidan",
  show_position: "Ariza shapkasida lavozim ko'rinsin",
  tariff_category_from: "Тарифный разряд от",
  tariff_category_to: "Тарифный разряд до",
  tariff_category: "Тарифный разряд",
  tariff_categories: "Тарифный разряд",
  coefficient: "Разряд",
  minimum_wages: "Минимальная оплата труда",
  minimum_wage: "Минимальная оплата труда",
  staff_business_trips: "Заявки на командировку",
  check_level: "проверить уровень",
  question_count: "Количество вопросов",
  min_point: "Мин Балл",
  Испытательные: "Испытательные",
  Действующие: "Действующие",
  Уволенные: "Уволенные",
  Женский: "Женский",
  Мужской: "Мужской",
  Официальный: "Официальный",
  Неофициальный: "Неофициальный",
  Оффлайн: "Оффлайн",
  Дистанционное: "Дистанционное",
  "Сдельная работа": "Сдельная работа",
  Активный: "Активный",
  Неактивный: "Неактивный",
  Наличный: "Наличный",
  Пластик: "Пластик",
  Банковский: "Банковский",
  open_vacancy: "Открытие вакансии",
  additional_work_salary: "Дополнительная оплата",
  period: "Период",
  this_week: "Текущая неделя",
  for_today: "Для сегодня",
  document_types: "Типы документов",
  for_tomorrow: "Для завтра",
  documentType: "Тип документа",
  welcome: "Hosgeldiniz!"
};
